<template>
  <base-view id="how-to-videos">
    <view-section>
      <h1 class="mb-12 text-5xl font-semibold">How To Videos</h1>
      <div class="flex flex-row flex-wrap items-center justify-between">
        <div class="w-full text-center md:w-1/2 lg:w-1/3">
          <router-link to="/how-to-videos/zn2gnc2gew">
            <h3 class="mb-4 text-xl font-semibold">
              What items are elligible?
            </h3>
          </router-link>
          <div class="mb-4">
            <iframe
              class="mx-auto mb-12 border border-neutral border-solid rounded"
              src="https://fast.wistia.net/embed/iframe/zn2gnc2gew?seo=false&playerColor=006840"
              allowfullscreen
              mozallowfullscreen
              webkitallowfullscreen
              oallowfullscreen
              msallowfullscreen
            />
          </div>
        </div>

        <div class="w-full text-center md:w-1/2 lg:w-1/3">
          <router-link to="/how-to-videos/fo3vt4l169">
            <h3 class="mb-4 text-xl font-semibold">
              How does my debit card work?
            </h3>
          </router-link>
          <div class="mb-4">
            <iframe
              class="mx-auto mb-12 border border-neutral border-solid rounded"
              src="https://fast.wistia.net/embed/iframe/fo3vt4l169?seo=false&playerColor=006840"
              allowfullscreen
              mozallowfullscreen
              webkitallowfullscreen
              oallowfullscreen
              msallowfullscreen
            />
          </div>
        </div>

        <!-- <div class="w-full text-center md:w-1/2 lg:w-1/3">
          <router-link to="/how-to-videos/jaw27m0mdd">
            <h3 class="mb-4 text-xl font-semibold">
              How do I register for a direct deposit?
            </h3>
          </router-link>
          <div class="mb-4">
            <iframe
              class="mx-auto mb-12 border border-neutral border-solid rounded"
              src="https://fast.wistia.net/embed/iframe/jaw27m0mdd?seo=false&playerColor=006840"
              allowfullscreen
              mozallowfullscreen
              webkitallowfullscreen
              oallowfullscreen
              msallowfullscreen
            />
          </div>
        </div> -->

        <div class="w-full text-center md:w-1/2 lg:w-1/3">
          <router-link to="/how-to-videos/33oc95u3ef">
            <h3 class="mb-4 text-xl font-semibold">
              How do I complete my profile?
            </h3>
          </router-link>
          <div class="mb-4">
            <iframe
              class="mx-auto mb-12 border border-neutral border-solid rounded"
              src="https://fast.wistia.net/embed/iframe/33oc95u3ef?seo=false&playerColor=006840"
              allowfullscreen
              mozallowfullscreen
              webkitallowfullscreen
              oallowfullscreen
              msallowfullscreen
            />
          </div>
        </div>

        <div class="w-full text-center md:w-1/2 lg:w-1/3">
          <router-link to="/how-to-videos/rmnvj4h23q">
            <h3 class="mb-4 text-xl font-semibold">
              How do I enroll in text alerts?
            </h3>
          </router-link>
          <div class="mb-4">
            <iframe
              class="mx-auto mb-12 border border-neutral border-solid rounded"
              src="https://fast.wistia.net/embed/iframe/rmnvj4h23q?seo=false&playerColor=006840"
              allowfullscreen
              mozallowfullscreen
              webkitallowfullscreen
              oallowfullscreen
              msallowfullscreen
            />
          </div>
        </div>

        <div class="w-full text-center md:w-1/2 lg:w-1/3">
          <router-link to="/how-to-videos/l43se5rk77">
            <h3 class="mb-4 text-xl font-semibold">
              How do I check my balance?
            </h3>
          </router-link>
          <div class="mb-4">
            <iframe
              class="mx-auto mb-12 border border-neutral border-solid rounded"
              src="https://fast.wistia.net/embed/iframe/l43se5rk77?seo=false&playerColor=006840"
              allowfullscreen
              mozallowfullscreen
              webkitallowfullscreen
              oallowfullscreen
              msallowfullscreen
            />
          </div>
        </div>

        <div class="w-full text-center md:w-1/2 lg:w-1/3">
          <router-link to="/how-to-videos/ymiebya71z">
            <h3 class="mb-4 text-xl font-semibold">How do I file a claim?</h3>
          </router-link>
          <div class="mb-4">
            <iframe
              class="mx-auto mb-12 border border-neutral border-solid rounded"
              src="https://fast.wistia.net/embed/iframe/ymiebya71z?seo=false&playerColor=006840"
              allowfullscreen
              mozallowfullscreen
              webkitallowfullscreen
              oallowfullscreen
              msallowfullscreen
            />
          </div>
        </div>

        <div class="w-full text-center md:w-1/2 lg:w-1/3">
          <router-link to="/how-to-videos/hgdfjxundu">
            <h3 class="mb-4 text-xl font-semibold">
              How do I make a bill payment?
            </h3>
          </router-link>
          <div class="mb-4">
            <iframe
              class="mx-auto mb-12 border border-neutral border-solid rounded"
              src="https://fast.wistia.net/embed/iframe/hgdfjxundu?seo=false&playerColor=006840"
              allowfullscreen
              mozallowfullscreen
              webkitallowfullscreen
              oallowfullscreen
              msallowfullscreen
            />
          </div>
        </div>

        <div class="w-full text-center md:w-1/2 lg:w-1/3">
          <router-link to="/how-to-videos/w069p3jvbk">
            <h3 class="mb-4 text-xl font-semibold">
              How do I download the mobile app?
            </h3>
          </router-link>
          <div class="mb-4">
            <iframe
              class="mx-auto mb-12 border border-neutral border-solid rounded"
              src="https://fast.wistia.net/embed/iframe/w069p3jvbk?seo=false&playerColor=006840"
              allowfullscreen
              mozallowfullscreen
              webkitallowfullscreen
              oallowfullscreen
              msallowfullscreen
            />
          </div>
        </div>

        <div class="w-full text-center md:w-1/2 lg:w-1/3">
          <router-link to="/how-to-videos/rjze84w3jy">
            <h3 class="mb-4 text-xl font-semibold">
              How do I view my HSA statement?
            </h3>
          </router-link>
          <div class="mb-4">
            <iframe
              class="mx-auto mb-12 border border-neutral border-solid rounded"
              src="https://fast.wistia.net/embed/iframe/rjze84w3jy?seo=false&playerColor=006840"
              allowfullscreen
              mozallowfullscreen
              webkitallowfullscreen
              oallowfullscreen
              msallowfullscreen
            />
          </div>
        </div>
      </div>
    </view-section>
  </base-view>
</template>

<script>
import BaseView from "@/components/layout/BaseView.vue";
import ViewSection from "@/components/layout/ViewSection.vue";

export default {
  components: {
    BaseView,
    ViewSection
  }
};
</script>
